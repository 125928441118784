export const mesnuList = [
  {
    name: "Our services",
    value: "our-services",
  },
  {
    name: "Our portfolio",
    value: "our-portfolio",
  },
  {
    name: "About us",
    value: "about-us",
  },
  {
    name: "Careers",
    value: "careers",
  },
  {
    name: "Contact us",
    value: "contact-us",
  },
];
